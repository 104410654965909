import React from "react"
import { TitleWithDescriptionBlock } from "../moleculs/title-with-description-block"
import { DSSTPresentation } from "../moleculs/dsst-presentation"
import { ProgramStructure } from "../svg/program-structure"
import { withStyles } from "@material-ui/core"
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import { Constants } from "../../constants"
import { Link } from "gatsby"
import { GatsbyImage } from "../images"
import { ContentContainer } from "../moleculs/content-container"

function Program({ classes, width }) {

  function renderLinkToPresentation() {
    if (!isWidthDown('sm', width)) return null

    return (
      <div className={classes.buttonContainer}>
        <Link to="/astraload-frontend-presentation" className={classes.fullScreenText}>
          <GatsbyImage
            name="presentationMobilePreview"
            width="100%"
          />
        </Link>
      </div>
    )
  }

  function renderPresentationBlock() {
    return (
      <div className={classes.presentationWrapper}>
        <h3 className={classes.subTitle}>Program details</h3>
        <div className={classes.presentation}>
          {renderLinkToPresentation()}
          <DSSTPresentation prWidth={1200} />
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className={classes.wrapper}>
        <div className={classes.sectionContainer}>
          <div className={classes.titleAndDescriptionContainer}>
            <TitleWithDescriptionBlock
              titleClassName={classes.textClassName}
              descriptionClassName={classes.textClassName}
              shouldAnimate={false}
              left
              {...Constants.program}
            />
          </div>
          <div className={classes.structureWrapper}>
            <ProgramStructure />
          </div>
        </div>
      </div>
      <ContentContainer customClass={classes.wrapper}>
        {renderPresentationBlock()}
      </ContentContainer>
    </div>
  )
}

const styles = theme => ({
  ...theme.animations.fadeInUp,
  titleAndDescriptionContainer: {
    maxWidth: 1250,
    width: "100%",
    padding: "0 40px 100px",
    boxSizing: "border-box",

    [theme.breakpoints.down("md")]: {
      padding: "0 40px 80px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px 50px",
    }
  },
  wrapper: {
    position: "relative",
    backgroundColor: theme.palette.background.effort,
    zIndex: 1000,
  },
  sectionContainer: {
    position: "relative",
    display: "flex",
    padding: "100px 0",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: theme.palette.background.effort,

    [theme.breakpoints.down("sm")]: {
      padding: "80px 0",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "50px 0",
    },
  },
  structureWrapper: {
    width: "100vw",
    margin: "0 auto",
    maxWidth: 1440,
    height: 508,
  },
  presentationWrapper: {

  },
  subTitle: {
    marginTop: "0px",
    marginBottom: "100px",
    fontSize: "40px",
    fontWeight: "700",
    letterSpacing: "-1px",
    lineHeight: "48px",
    color: theme.palette.text.white,

    [theme.breakpoints.down("md")]: {
      marginBottom: "80px",
      fontSize: "30px",
      lineHeight: "40px",
    },

    [theme.breakpoints.down("xs")]: {
      marginBottom: "50px",
      fontSize: "30px",
      lineHeight: "40px",
    },
  },
  presentation: {
    margin: "0 auto",
    boxSizing: "border-box",
    width: "1170px",
    height: "650px",
    borderRadius: "24px",
    boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.15)",
    backgroundColor:"#ffffff",
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "388px",
      borderRadius: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "280px",
      maxHeight: "158px",
      borderRadius: "8px",
    },
  },
  textClassName: {
    color: theme.palette.text.white,
  },
  fadeInUp: {
    ...theme.animations.itemFadeInUp,
    animationDelay: ".35s",

    [theme.breakpoints.down("sm")]: {
      ...theme.animations.noAnimation,
    },
  },
  buttonContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
    backgroundColor: "rgba(255,255,255,0.8)"
  },
  fullScreenText: {
    cursor: "pointer",
    color: "#000000",
    width: "100%",
    height: "100%"
  },
})

// eslint-disable-next-line
Program = withWidth()(withStyles(styles)(Program))

export { Program }
