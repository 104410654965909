import React, { useRef } from "react"
import Typography from "@material-ui/core/Typography"
import { BenefitItem } from "../atoms/benefit-item"
import { Constants } from "../../constants"
import { Grid, withStyles } from "@material-ui/core"
import withWidth from "@material-ui/core/withWidth"
import { useInView } from "react-intersection-observer"
import useShouldAnimate from "../../hooks/useShouldAnimate"
import useScrollPosition from "../../hooks/useScrollPosition"
import { ContentContainer } from "./content-container"

function Benefits({ classes }) {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  })
  const scrollPosition = useScrollPosition()
  const sectionRef = useRef()
  const sectionPosition = sectionRef?.current?.offsetTop
  const shouldAnimate = useShouldAnimate(sectionPosition, scrollPosition)

  const { benefits: data } = Constants

  const benefitsClasses =
    inView
      ? `${classes.benefits} ${classes.fadeInUp}`
      : classes.benefits



  function renderBenefits() {
    return (
      <div ref={ref} className={benefitsClasses}>
        <Grid
          container
          spacing={6}
          className={classes.benefitsContainer}
        >
          {data.items.map(({ key, imageName, text }) => (
            <Grid
              item
              container
              lg={6}
              md={6}
              sm={6}
              xs={12}
            >
              <Grid
                item
                lg={10}
                sm={12}
              >
                <BenefitItem key={key} imageName={imageName} text={text} />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
    )
  }

  return (
    <ContentContainer ref={sectionRef} className={classes.wrapper}>
      <Typography
        variant="h2"
        className={`${classes.title} ${classes.fadeInUp}`}
      >
        {data.title}
      </Typography>
      {renderBenefits()}
    </ContentContainer>
  )
}

const styles = theme => ({
  ...theme.animations.fadeInUp,
  title: {
    fontWeight: "700",
    color: theme.palette.text.secondary,
    letterSpacing: "-1px",

    [theme.breakpoints.down("xs")]: {
      fontSize: "30px",
      lineHeight: "40px",
    },
  },
  benefits: {
    paddingTop: "100px",
    opacity: 0,

    [theme.breakpoints.down("xs")]: {
      paddingTop: "80px",
    },
  },
  benefitsContainer: {
    gap: "80px 0",
  },
  fadeInUp: {
    ...theme.animations.itemFadeInUp,

    [theme.breakpoints.down("sm")]: {
      ...theme.animations.noAnimation,
    },
  },
})

// eslint-disable-next-line
Benefits = withWidth()(withStyles(styles)(Benefits))

export { Benefits }
