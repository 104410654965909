import React from "react"
import { withStyles } from "@material-ui/core"
import { GatsbyImage } from "../images"

function BenefitItem({ classes, imageName, text, ...props }) {
  return (
    <div className={classes.container} {...props}>
      <div className={classes.image}>
        <GatsbyImage name={imageName} />
      </div>
      <p className={classes.text}>{text}</p>
    </div>
  )
}

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      display: "block"
    },

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "auto",
      display: "block"
    },
  },
  image: {
    marginRight: "30px",
    width: "100px",
    height: "100px",
    "& > div": {
      width: "100px",
      height: "100px",
    },

    [theme.breakpoints.down("sm")]: {
      width: "80px",
      height: "80px",
      marginRight: "0px",
      marginBottom: "30px",

      "& > div": {
        width: "80px",
        height: "80px",
      },
    },
  },
  text: {
    margin: 0,
    fontSize: "24px",
    fontWeight: "400",
    lineHeight: "32px",
    letterSpacing: "-0.5px",
    color: theme.palette.text.primary,

    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      lineHeight: "30px",
    },
  },
})

// eslint-disable-next-line
BenefitItem = withStyles(styles)(BenefitItem)

export { BenefitItem }
