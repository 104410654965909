import React, { useState } from "react"
import Typography from "@material-ui/core/Typography"
import QAItem from "../moleculs/qa-item"
import { withStyles } from "@material-ui/core"
import { ContentContainer } from "../moleculs/content-container"

function QASection({ classes, title, items }) {
  const [expanded, setExpanded] = useState(() => items[0].id)

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <ContentContainer customClass={classes.bg}>
      <Typography className={classes.title}>{title}</Typography>
      <div className={classes.items}>
        {items?.map(item => (
          <QAItem
            key={item.id}
            expanded={expanded}
            item={item}
            handleChange={handleChange}
          />
        ))}
      </div>
    </ContentContainer>
  )
}

const styles = theme => ({
  bg: {
    backgroundColor: "#ECF2F6"
  },
  title: {
    marginBottom: "70px",
    fontSize: "54px",
    fontWeight: "700",
    lineHeight: "55px",
    letterSpacing: "-1px",
    color: theme.palette.text.secondary,

    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
      lineHeight: "40px",
      marginBottom: 50
    },
  },
  items: {
    margin: "0 auto",

    ["@media screen and (max-width: 840px)"]: {
      width: "100%",
    },
  },
})

// eslint-disable-next-line
QASection = withStyles(styles)(QASection)

export { QASection }
