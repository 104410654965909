import React from "react"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { withStyles } from "@material-ui/core/styles"

const Accordion = withStyles({
  root: {
    padding: "20px 0px",
    backgroundColor: "transparent",
    boxShadow: "none",
    borderRadius: "0 !important",
    "&:not(:last-child)": {
      borderBottom: "1px solid #CCCCCC",
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    paddingLeft: "0px",
    minHeight: "38px",
    "&$expanded": {
      minHeight: "38px",
    },
  },
  content: {
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  expandIcon: {
    padding: 0,
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles({
  root: {
    padding: "20px 0 0 0",
    "&$expanded": {},
  },
  content: {
    "&$expanded": {},
  },
  expanded: {},
})(MuiAccordionDetails)

const MoreIcon = withStyles({
  root: {
    fill: "#37474F",
    padding: "0",
  },
})(ExpandMoreIcon)

export default function QAItem({ classes, item, expanded, handleChange }) {
  return (
    <Accordion expanded={expanded === item.id} onChange={handleChange(item.id)}>
      <AccordionSummary
        expandIcon={<MoreIcon />}
        aria-controls={`${item.id}-content"`}
        id={`${item.id}-header`}
      >
        <Typography className={classes.title}>{item.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className={classes.summary}>{item.summary}</Typography>
      </AccordionDetails>
    </Accordion>
  )
}

const styles = theme => ({
  title: {
    fontSize: "24px",
    fontWeight: "400",
    lineHeight: "38px",
    letterSpacing: "-1px",
    color: theme.palette.text.secondary,
  },
  summary: {
    fontSize: "24px",
    fontWeight: "300",
    lineHeight: "32px",
    letterSpacing: "-0.5px",
    color: theme.palette.text.primary,
  },
})

QAItem = withStyles(styles)(QAItem)

export { QAItem }
