import React, { useRef } from "react"
import { TitleWithDescriptionBlock } from "./title-with-description-block"
import { withStyles } from "@material-ui/core"
import useShouldAnimate from "../../hooks/useShouldAnimate"
import useScrollPosition from "../../hooks/useScrollPosition"
import { ContentContainer } from "./content-container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { useInView } from "react-intersection-observer"

function WhatTeamDoBlock(props) {
  const { classes, title, list } = props
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const scrollPosition = useScrollPosition()
  const sectionRef = useRef()
  const sectionPosition = sectionRef?.current?.offsetTop
  const shouldAnimate = useShouldAnimate(sectionPosition, scrollPosition)

  function setItemClass () {
    if (inView) return `${classes.itemBlock} ${classes.itemAnimation}`
    return classes.itemBlock
  }

  function renderItem(text, key) {
    return (
      <Grid
        item
        lg={3}
        md={6}
        xs={12}
        key={key}
        className={setItemClass()}
      >
        <Typography variant="h5" className={classes.text}>
          {text}
        </Typography>
      </Grid>
    )
  }

  function renderListContainer() {
    return (
      <Grid
        container
        spacing={6}
        className={classes.listContainer}
        ref={ref}
      >
        {list.map(renderItem)}
      </Grid>
    )
  }

  return (
    <ContentContainer ref={sectionRef} customClass={classes.container}>
      <TitleWithDescriptionBlock
        title={title}
        shouldAnimate={shouldAnimate}
        left={true}
      />

      {renderListContainer()}
    </ContentContainer>
  )
}

const styles = theme => ({
  ...theme.animations.fadeInUp,
  container: {
    backgroundColor: "#ECF2F6",
  },
  listContainer: {
    marginTop: 100,

    [theme.breakpoints.down("md")]: {
      marginTop: 80,
      gap: "80px 0"
    },

    [theme.breakpoints.down("sm")]: {
      marginTop: 50,
      gap: "50px 0"
    },
  },
  text: {
    fontSize: 24,
    lineHeight: "32px",
    color: theme.palette.text.secondary
  },
  itemBlock: {
    animationDelay: "0.35s",

    "&:nth-child(1)":{
      animationDelay: "0.35s",
    },
    "&:nth-child(2)":{
      animationDelay: "0.7s",
    },
    "&:nth-child(3)":{
      animationDelay: "1.05s",
    },

    "&:nth-child(4)":{
      animationDelay: "1.4s",
    },

    [theme.breakpoints.down("sm")]: {
      ...theme.animations.noAnimation,
    },
  },
  itemAnimation: {
    ...theme.animations.itemFadeInUp,

    "&:nth-child(1)":{
      animationDelay: "0.35s",
    },
    "&:nth-child(2)":{
      animationDelay: "0.7s",
    },
    "&:nth-child(3)":{
      animationDelay: "1.05s",
    },

    "&:nth-child(4)":{
      animationDelay: "1.4s",
    },

    [theme.breakpoints.down("sm")]: {
      ...theme.animations.noAnimation,
    },
  },
})

// eslint-disable-next-line
WhatTeamDoBlock = withStyles(styles)(WhatTeamDoBlock)

export { WhatTeamDoBlock }
