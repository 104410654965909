import React from "react"
import { Layout } from "../components/organism/layout"
import Seo from "../components/seo"
import { WhatTeamDoBlock } from "../components/moleculs/what-team-do-block"
import { Benefits } from "../components/moleculs/benefits"
import { Program } from "../components/organism/program"
import { BookTeam } from "../components/organism/book-team"
import { Constants } from "../constants"
import { QASection } from "../components/organism/qa-section"
import { ContactsBlock } from "../components/organism/contacts-block"


export default function IndexPage({ location }) {
  const {
    dsstCallToActionBlock,
    dsstHeader,
    whatTeamWillDo,
    qaBlock,
  } = Constants

  return (
    <Layout
      background="dsstBg"
      callToActionBlock={dsstCallToActionBlock}
      callToActionButton
      isHome
      {...dsstHeader}
    >
      <Seo
        location={location}
        title="Astraload frontend program"
        description="A vetted team of design, development and facilitation professionals who will scale your frontend development with state-of-the art tooling, robust processes and methodical coaching by executing a 12-week supercharged enhancement program"
      />

      <main>
        <Benefits />
        <WhatTeamDoBlock {...whatTeamWillDo} />
        <Program />
        <ContactsBlock callToActionBlockInfo={dsstCallToActionBlock} />
        <QASection {...qaBlock} />
      </main>
    </Layout>
  )
}
