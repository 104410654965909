import React from "react"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { GatsbyImageBackground } from "../images"
import { withStyles } from "@material-ui/core"
import { Constants } from "../../constants"
import useCalendy from "../../hooks/useCalendy"

function BookTeam({ classes, callToActionBlockInfo }) {
  const { title, description } = callToActionBlockInfo
  const { quoteLink } = Constants
  const { initCalendy } = useCalendy()

  function openForm() {
    window.open(quoteLink, "_blank")
  }

  return (
    <div className={classes.footerDark}>
      <GatsbyImageBackground
        className={`${classes.container} ${classes.paddingContainer}`}
        name="spaceBackgroundImage"
      >
        <div className={classes.content}>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {description}
          </Typography>
          <Button className={classes.button} onClick={openForm}>
            Book the team
          </Button>
          <div className={classes.links}>
            <div className={classes.section}>
              <Typography variant="subtitle" className={classes.sectionTitle}>
                Have any questions?
              </Typography>
              <a
                href="https://blog.astraloadhq.com/"
                className={classes.link}
                target="_blank"
              >
                Read our blog
              </a>
              <a
                href="https://t.me/joinchat/DQIDQU7Naz7doknz7u8meQ"
                className={classes.link}
                target="_blank"
              >
                Join Telegram group
              </a>
              <a href={quoteLink} className={classes.link} target="_blank">
                Get a quote
              </a>
            </div>
            <div className={classes.section}>
              <Typography variant="subtitle" className={classes.sectionTitle}>
                Ready to start working with Astraload?
              </Typography>
              <div className={classes.schedule}>
                <div
                  onClick={initCalendy}
                  className={`${classes.link} ${classes.inline}`}
                >
                  Schedule a meeting
                </div>{" "}
                with Astraload CEO — Evgeny Lukianchikov
              </div>
            </div>
          </div>
        </div>
      </GatsbyImageBackground>
    </div>
  )
}

const styles = theme => ({
  footerDark: {
    background: `linear-gradient(0deg, ${theme.palette.background.lightGray} 60%, ${theme.palette.background.effort} 60%)`,
  },
  container: {
    display: "flex",
    height: "1475px",
    flexDirection: "column",
    justifyContent: "center",
    clipPath: "polygon(0 23.3%, 100% 0, 100% 82%, 0% 100%)",
    "-webkit-clip-path": "polygon(0 23.3%, 100% 0, 100% 82%, 0% 100%)",
    opacity: "1 !important",
    [theme.breakpoints.down("sm")]: {
      height: "1155px",
      top: "-47px",
      paddingTop: "10px",
      paddingBottom: "0px",
      clipPath: "polygon(0 15.6%, 100% 0, 100% 82%, 0% 100%)",
      "-webkit-clip-path": "polygon(0 15.6%, 100% 0, 100% 82%, 0% 100%)",
    },
    [theme.breakpoints.down("xs")]: {
      top: "0",
      height: "1163px",
      clipPath: ({ isHome }) =>
        isHome
          ? "polygon(0 5%, 100% 0, 100% 100%, 0% 100%)"
          : "polygon(0 6.5%, 100% 0, 100% 100%, 0% 100%)",
      "-webkit-clip-path": ({ isHome }) =>
        isHome
          ? "polygon(0 5%, 100% 0, 100% 92%, 0% 100%)"
          : "polygon(0 6.5%, 100% 0, 100% 92%, 0% 100%)",
    },
  },
  paddingContainer: {
    paddingTop: "100px",
    height: "1362px",
    [theme.breakpoints.down("sm")]: {
      height: "1295px",
      top: "0px",
      padding: "20px 35px 0 35px",
      clipPath: "polygon(0 14%, 100% 0, 100% 82%, 0% 100%)",
      "-webkit-clip-path": "polygon(0 14%, 100% 0, 100% 82%, 0% 100%)",
    },
    [`@media screen and (max-width: 760px)`]: {
      padding: "100px 35px 0 35px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "160px 20px 0 20px",
      height: "1060px",
      justifyContent: "flex-start",
    },
  },
  content: {
    margin: "0 auto",
    width: "770px",

    ["@media screen and (max-width: 840px)"]: {
      width: "100%",
    },

    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  title: {
    marginBottom: "30px",
    fontSize: "54px",
    fontWeight: "700",
    lineHeight: "55px",
    letterSpacing: "-1px",
    color: theme.palette.text.white,
    whiteSpace: "pre-line",

    [theme.breakpoints.down("xs")]: {
      fontSize: "30px",
      lineHeight: "40px",
    },
  },
  description: {
    marginBottom: "50px",
    width: "500px",
    fontSize: "24px",
    fontWeight: "300",
    lineHeight: "38px",
    letterSpacing: "-0.5px",
    color: theme.palette.text.white,

    [theme.breakpoints.down("xs")]: {
      margin: "0 auto 66px auto",
      width: "280px",
      fontSize: "20px",
      lineHeight: "30px",
    },
  },
  button: {
    marginBottom: "80px",

    [theme.breakpoints.down("xs")]: {
      margin: "0 auto 80px",
    },
  },
  links: {
    display: "flex",
    paddingTop: "50px",
    justifyContent: "space-between",
    borderTop: "1px solid #CCC",

    [`@media screen and (max-width: 760px)`]: {
      flexDirection: "column",
    },

    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
      textAlign: "center",
    },
  },
  link: {
    display: "block",
    fontSize: "24px",
    fontWeight: "400",
    lineHeight: "38px",
    textDecoration: "underline",
    cursor: "pointer",

    "&:hover": {
      color: theme.palette.text.linkHover,
    },
  },
  inline: {
    display: "inline-block",
  },
  section: {
    maxWidth: "370px",

    [theme.breakpoints.down("sm")]: {
      width: "338px",
    },

    [`@media screen and (max-width: 760px)`]: {
      width: "280px",

      "&:not(:last-child)": {
        marginBottom: "80px",
      },
    },
  },
  sectionTitle: {
    display: "block",
    marginBottom: "30px",
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "38px",
    color: theme.palette.text.white,
  },
  schedule: {
    fontSize: "24px",
    fontWeight: "400",
    lineHeight: "38px",
    color: theme.palette.text.white,
  },
})

BookTeam = withStyles(styles)(BookTeam)

export { BookTeam }
